import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addImageToThePage,
  addNewSpread,
  createPhotobook,
  deleteImage,
  deleteSpread,
  fetchLayouts,
  fetchPhotobooks,
  replaceImage,
  showSuccessNotify,
  swapImage,
  updateImageBgPosition,
  updatePhotobookLayout,
  updatePhotobookTitle
} from './actions';
import { addCoverSpread } from 'modules/ScrPhotobook/helpers/addCoverSpread';

export interface IBoundsRelative {
  heightPercent: number;
  widthPercent: number;
  leftPercent: number;
  topPercent: number;
}

export interface ISpreadImage {
  id: string;
  frameIndex: number;
  boundsRelative: IBoundsRelative;
}

export interface IPhotobookSpread {
  _id: string;
  index: number;
  frameLayout: null | string;
  images: ISpreadImage[];
  isCoverSlide?: boolean;
  cover?: string;
}

export interface IPhotobookPricing {
  price?: number;
  priceForAdditionalPages?: number;
  priceTotal?: number;
}

type PhotobookMode = 'crop' | 'swap' | '';

export interface IPhotobook {
  _id: string;
  _user: string;
  _endcustomer: string;
  _product: string;
  _collection: string;

  title: string;
  slug: string;
  aspectRatio: number;

  productImage: string;
  details: {
    usedImages: string[];
    numberOfUsedImages: number;
    numberOfPages: number;
    numberOfSpreads: number;
  };
  productInformation: {
    size: number;
    color: string;
    minAmountOfPages: number;
    maxAmountOfPages: number;
  };
  productDetails: {
    color: string;
  };

  spreads: IPhotobookSpread[];

  availableSelections: string[];
  authorizedEditors: string[];
  sharedViewers: string[];

  pricing: IPhotobookPricing;

  isDeleted: boolean;
  finalDeletionAt: Date;

  createdAt: string;
  updatedAt: string;
}

export interface ILayout {
  _id: string;
  numberOfFrames: number;
  frames: Array<{ boundsRelative: IBoundsRelative }>;
}

export interface IPhotobookState {
  photobooks: IPhotobook[];
  layouts: ILayout[];
  activePageIdx: number | null;
  activatedImageId: string;
  isImageDragged?: boolean;
  isMobileCoverEdit?: boolean;
  mode: PhotobookMode;
  lastUpdate?: string;
  isUpdateProcessed?: boolean;
}

const initialState: IPhotobookState = {
  photobooks: [],
  layouts: [],
  activePageIdx: 0,
  activatedImageId: '',
  isImageDragged: false,
  isMobileCoverEdit: false,
  mode: '',
  lastUpdate: '',
  isUpdateProcessed: false
};

const photobookSlice = createSlice({
  name: 'photobook',
  initialState,
  reducers: {
    setPhotobook(state, { payload }: PayloadAction<IPhotobookState | null>) {
      Object.assign(state, payload);
    },
    setActivePageIdx(state, { payload }: PayloadAction<number | null>) {
      const isPageChanged = state.activePageIdx !== payload;
      if (!isPageChanged) return;

      state.activePageIdx = payload;
      state.activatedImageId = '';
      state.mode = '';
    },
    setFirstPageAsCover(state, { payload }: PayloadAction<{ id: string }>) {
      const currentPhotobookIdx = state.photobooks.findIndex((item) => item._id === payload.id);

      state.photobooks[currentPhotobookIdx].spreads[0].isCoverSlide = true;
    },
    changePhotobookTitle(state, { payload }: PayloadAction<{ id: string; value: string }>) {
      const currentPhotobookIdx = state.photobooks.findIndex((item) => item._id === payload.id);

      state.photobooks[currentPhotobookIdx].title = payload.value;
    },
    updateSpreads(
      state,
      { payload }: PayloadAction<{ photobookId: string; spreads: IPhotobookSpread[] }>
    ) {
      const currentPhotobookIdx = state.photobooks.findIndex(
        (item) => item._id === payload.photobookId
      );

      const spreads = [...payload.spreads];

      const coverSpread = {
        _id: '',
        frameLayout: '',
        images: [],
        index: 0,
        isCoverSlide: true
      };

      spreads.unshift(coverSpread);

      const spreadsWithIndex = spreads.map((spread, index) => ({ ...spread, index }));

      state.photobooks[currentPhotobookIdx].spreads = spreadsWithIndex;
    },
    updatePricing(
      state,
      { payload }: PayloadAction<{ photobookId: string; pricing: IPhotobookPricing }>
    ) {
      const currentPhotobookIdx = state.photobooks.findIndex(
        (item) => item._id === payload.photobookId
      );

      state.photobooks[currentPhotobookIdx].pricing = payload.pricing;
    },
    setActiveImageId(state, { payload }: PayloadAction<{ activatedImageId: string }>) {
      state.activatedImageId = payload.activatedImageId;
      state.mode = '';
    },
    startImageDrag(state) {
      state.isImageDragged = true;
    },
    endImageDrag(state) {
      state.isImageDragged = false;
    },
    setPhotobookMode(state, { payload }: PayloadAction<PhotobookMode>) {
      const isSameModeSelected = state.mode === payload;
      state.mode = isSameModeSelected ? '' : payload;
    },
    setPhotobookCover(state, { payload }: PayloadAction<{ photobookId: string; cover: string }>) {
      const currentPhotobookIdx = state.photobooks.findIndex(
        (item) => item._id === payload.photobookId
      );

      state.photobooks[currentPhotobookIdx].productDetails.color = payload.cover;
    },
    startMobileCoverEditing(state) {
      state.isMobileCoverEdit = true;
    },
    endMobileCoverEditing(state) {
      state.isMobileCoverEdit = false;
    },
    setLastUpdate(state, { payload }: PayloadAction<string>) {
      state.lastUpdate = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPhotobooks.fulfilled, (state, { payload }) => {
      const { photobooksWithCoverSpread } = addCoverSpread(payload);

      state.photobooks = photobooksWithCoverSpread;
    });
    builder.addCase(fetchLayouts.fulfilled, (state, { payload }) => {
      state.layouts = payload;
    });
    builder.addCase(createPhotobook.fulfilled, (state, { payload }) => {
      state.photobooks = [...state.photobooks, payload];
      showSuccessNotify();
    });

    // Processing actions
    builder.addCase(updatePhotobookTitle.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(updatePhotobookTitle.fulfilled, (state) => {
      // showSuccessNotify();
      state.isUpdateProcessed = false;
    });

    builder.addCase(updatePhotobookLayout.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(updatePhotobookLayout.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(addNewSpread.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(addNewSpread.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(deleteSpread.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(deleteSpread.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(addImageToThePage.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(addImageToThePage.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(replaceImage.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(replaceImage.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(updateImageBgPosition.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(updateImageBgPosition.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(deleteImage.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(deleteImage.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(swapImage.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(swapImage.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });
  }
});

export const {
  setPhotobook,
  setActivePageIdx,
  changePhotobookTitle,
  updateSpreads,
  updatePricing,
  setActiveImageId,
  startImageDrag,
  endImageDrag,
  setPhotobookMode,
  setPhotobookCover,
  startMobileCoverEditing,
  endMobileCoverEditing,
  setLastUpdate
} = photobookSlice.actions;

export const photobookReducer = photobookSlice.reducer;
